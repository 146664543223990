import React, { useState } from "react";
import { Link } from "gatsby";
import { isMobile } from "react-device-detect";
import VisibilitySensor from "react-visibility-sensor";
import LocalSeoImg from "../../assets/images/arrow.png";
import NationalSeoImg from "../../assets/images/flag.png";
import HeadlessImg from "../../assets/images/brain.png";
import LocalSeoGif from "../../assets/gifs/arrow.gif";
import NationalSeoGif from "../../assets/gifs/flag.gif";
import HeadlessGif from "../../assets/gifs/brain.gif";
import { loadImage } from "../../utils/generalFun";

const PageList = () => {
  const [localSrc, setLocal] = useState(LocalSeoImg);
  const [nationalSrc, setNational] = useState(NationalSeoImg);
  const [headlessSrc, setHeadlessSrc] = useState(HeadlessImg);

  const onChangeVisibilityLocal = async (isVisible) => {
    if (isVisible && isMobile) {
      let img = await loadImage(LocalSeoGif);
      if (img === "success") {
        document.querySelector(
          ".pagelist-first"
        ).style.backgroundImage = `url(${LocalSeoGif})`;
      }
    } else {
      document.querySelector(
        ".pagelist-first"
      ).style.backgroundImage = `url(${LocalSeoImg})`;
    }
  };

  const onChangeVisibilityNational = async (isVisible) => {
    if (isVisible && isMobile) {
      let img = await loadImage(NationalSeoGif);
      if (img === "success") {
        document.querySelector(
          ".pagelist-second"
        ).style.backgroundImage = `url(${NationalSeoGif})`;
      }
    } else {
      document.querySelector(
        ".pagelist-second"
      ).style.backgroundImage = `url(${NationalSeoImg})`;
    }
  };
  const onChangeVisibilityHeadless = async (isVisible) => {
    if (isVisible && isMobile) {
      let img = await loadImage(HeadlessGif);
      if (img === "success") {
        document.querySelector(
          ".pagelist-third"
        ).style.backgroundImage = `url(${HeadlessGif})`;
      }
    } else {
      document.querySelector(
        ".pagelist-third"
      ).style.backgroundImage = `url(${HeadlessImg})`;
    }
  };
  return (
    <section
      class="pageList"
      style={{
        borderBottom: "1px solid #fff",
      }}
    >
      <VisibilitySensor onChange={onChangeVisibilityLocal} partialVisibility>
        <Link
          class="pageList-item _component-color-red pagelist-first"
          to="/dispensary-seo"
          title="Learn about the science behind Civilized Savage"
          style={{
            backgroundImage: `url(${localSrc})`,
            color: "#fff",
            borderRight: isMobile ? "none" : "1px solid #fff",
            borderTop: "1px solid #fff",
            backgroundColor: "#000",
          }}
          onMouseEnter={() => setLocal(LocalSeoGif)}
          onMouseLeave={() => setLocal(LocalSeoImg)}
        >
          <div class="pageList-content">
            <header class="pageList-header">
              <h2 class="pageList-headline">Dispensary SEO</h2>
            </header>
            <div class="pageList-asset">
              <img src={localSrc} className="pageList-image" alt="Local" />
            </div>
            <p
              class="pageList-cta button -cta -color-white"
              style={{ color: "#fff" }}
            >
              <span class="pageList-cta-text">Our Strategy </span>
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.10081 0L5.88245 1.23617L10.7016 6.12576H0V7.87423H10.7016L5.88245 12.7638L7.10081 14L14 7L7.10081 0Z"
                  fill="#fff"
                ></path>
              </svg>
            </p>
          </div>
        </Link>
      </VisibilitySensor>
      <VisibilitySensor onChange={onChangeVisibilityNational} partialVisibility>
        <Link
          class="pageList-item _component-color-blue pagelist-second"
          to="/delivery-cannabis-seo"
          title="Learn about Civilized Savage's approach"
          style={{
            backgroundImage: `url(${nationalSrc})`,
            color: "#fff",
            backgroundColor: "#000",
            borderTop: "1px solid #fff",
            borderRight: isMobile ? "none" : "1px solid #fff",
          }}
          onMouseEnter={() => setNational(NationalSeoGif)}
          onMouseLeave={() => setNational(NationalSeoImg)}
        >
          <div class="pageList-content">
            <header class="pageList-header">
              <h2 class="pageList-headline">Delivery & E-Comm</h2>
            </header>
            <div class="pageList-asset">
              <img
                src={nationalSrc}
                className="pageList-image"
                alt="National"
              />
            </div>
            <p
              class="pageList-cta button -cta -color-white"
              style={{ color: "#fff" }}
            >
              <span class="pageList-cta-text">Our Approach</span>
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.10081 0L5.88245 1.23617L10.7016 6.12576H0V7.87423H10.7016L5.88245 12.7638L7.10081 14L14 7L7.10081 0Z"
                  fill="#fff"
                ></path>
              </svg>
            </p>
          </div>
        </Link>
      </VisibilitySensor>
      <VisibilitySensor onChange={onChangeVisibilityHeadless} partialVisibility>
        <Link
          class="pageList-item _component-color-green pagelist-third"
          to="/delta-8-seo"
          title="Learn about practical applications"
          style={{
            backgroundImage: `url(${headlessSrc})`,
            color: "#fff",
            backgroundColor: "#000",
            borderTop: "1px solid #fff",
          }}
          onMouseEnter={() => setHeadlessSrc(HeadlessGif)}
          onMouseLeave={() => setHeadlessSrc(HeadlessImg)}
        >
          <div class="pageList-content">
            <header class="pageList-header">
              <h2 class="pageList-headline">Delta 8 Seo</h2>
            </header>
            <div class="pageList-asset">
              <img
                src={headlessSrc}
                className="pageList-image"
                alt="Headless"
              />
            </div>
            <p
              class="pageList-cta button -cta -color-white"
              style={{ color: "#fff" }}
            >
              <span class="pageList-cta-text">Dominate Delta Search</span>
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.10081 0L5.88245 1.23617L10.7016 6.12576H0V7.87423H10.7016L5.88245 12.7638L7.10081 14L14 7L7.10081 0Z"
                  fill="#fff"
                ></path>
              </svg>
            </p>
          </div>
        </Link>
      </VisibilitySensor>
    </section>
  );
};

export default PageList;
