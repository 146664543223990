import React from "react";
import { Link } from "gatsby";
import { isMobile } from "react-device-detect";
import VisibilitySensor from "react-visibility-sensor";
import HomeImg from "../../assets/images/home-new.png";
import HomeGif from "../../assets/gifs/home.gif";
import { loadImage } from "../../utils/generalFun";

const Hero = () => {
  const onChangeVisibility = async (isVisible) => {
    if (isVisible && isMobile) {
      let img = await loadImage(HomeGif);
      if (img === "success") {
        document.querySelector(
          ".-home"
        ).style.backgroundImage = `url(${HomeGif})`;
      }
    } else {
      document.querySelector(
        ".-home"
      ).style.backgroundImage = `url(${HomeImg})`;
    }
  };

  const _onMouseEnter = async () => {
    let img = await loadImage(HomeGif);
    if (img === "success") {
      document.querySelector(
        ".-home"
      ).style.backgroundImage = `url(${HomeGif})`;
    }
  };
  const _onMoutLeave = () => {
    document.querySelector(".-home").style.backgroundImage = `url(${HomeImg})`;
  };

  return (
    <VisibilitySensor onChange={onChangeVisibility} partialVisibility>
      <section
        class="hero -home"
        style={{ backgroundImage: `url(${HomeImg})` }}
        onMouseEnter={_onMouseEnter}
        onMouseLeave={_onMoutLeave}
        role="none"
      >
        <div class="hero-stack">
          <h1 class="hero-headline">
            SEO Cannabis Agency
            <br />
            <span>SEO & Web Development for Cannabis Industry</span>
          </h1>
          <p class="hero-cta -home">
            <Link
              class="button -cta"
              to="/delta-8-seo"
              title="SEO Cannabis Agency"
            >
              BUILD A BETTER WEBSITE
              <span class="arrowPacman">
                <span class="arrowPacman-clip">
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.10081 0L5.88245 1.23617L10.7016 6.12576H0V7.87423H10.7016L5.88245 12.7638L7.10081 14L14 7L7.10081 0Z"
                      fill="#fff"
                    ></path>
                  </svg>
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.10081 0L5.88245 1.23617L10.7016 6.12576H0V7.87423H10.7016L5.88245 12.7638L7.10081 14L14 7L7.10081 0Z"
                      fill="#fff"
                    ></path>
                  </svg>
                </span>
              </span>
            </Link>
            <Link class="button -cta" to="/contact">
              IMPROVE YOUR SEARCH RESULTS
              <span class="arrowPacman">
                <span class="arrowPacman-clip">
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.10081 0L5.88245 1.23617L10.7016 6.12576H0V7.87423H10.7016L5.88245 12.7638L7.10081 14L14 7L7.10081 0Z"
                      fill="white"
                    ></path>
                  </svg>
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.10081 0L5.88245 1.23617L10.7016 6.12576H0V7.87423H10.7016L5.88245 12.7638L7.10081 14L14 7L7.10081 0Z"
                      fill="white"
                    ></path>
                  </svg>
                </span>
              </span>
            </Link>
          </p>
        </div>
      </section>
    </VisibilitySensor>
  );
};

export default Hero;
