import React, { Fragment, useState } from "react";
import { Helmet } from "react-helmet";
import Header from "../components/header";
import Hero from "../components/hero";
import PageList from "../components/pageList";
import JobSection from "../components/jobSection";
import Footer from "../components/footer";

const IndexPage = () => {
  const [showModal, setShowModal] = useState(false);
  return (
    <Fragment>
      <Helmet>
        <title>SEO Cannabis Agency</title>
        <meta
          name="description"
          content="SEO Cannabis Agency is the best SEO marketing team dedicated to the cannabis and hemp industry. Our expertise ranges from e-commerce to delivery and even wholesale. We are the best SEO cannabis agency in the United States."
        />
        <link href="https://seocannabis.agency/" rel="canonical" />
      </Helmet>
      <main>
        <Header />
        <Hero />
        <PageList />
        <JobSection showModal={() => setShowModal(true)} />
        <Footer isTopborder={true} />
      </main>
      {showModal && (
        <div className="popup-overlay">
          <div className="content-container">
            <div className="content-wrapper">
              <div className="header">
                <div className="inner">
                  <div>Why SEO Cannabis Agency</div>
                  <div
                    className="close"
                    role='none'
                    onClick={() => setShowModal(false)}
                  ></div>
                </div>
              </div>
              <div className="content">
                <p>
                  Your web presence is the #1 credibility indicator for
                  potential customers. Is your cannabis brand losing out on
                  sales because of its low search results ranking? Stop
                  stressing about your SEO strategy. We can help your brand get
                  to the top spot using our advanced SEO knowledge.
                  <br />
                  <br />
                  We help your cannabis business succeed by taking control of
                  your web presence. Our team helps you improve your spot in
                  your category’s search results by analyzing your market and
                  the value of your keywords. If your cannabis business isn’t at
                  the top of the search results where it should be, let our team
                  of specialists rethink your SEO strategy.
                  <br />
                  <br />
                  To succeed as a cannabis business, you need to rank high when
                  customers search for your brand’s category. Potential
                  customers buy from cannabis businesses that rank high because
                  it signals to them that they are reputable. Stand out in the
                  crowded cannabis market by using an SEO strategy developed by
                  our team of expert SEO specialists.
                  <br />
                  <br />
                  Prove to your customers that your cannabis brand is the best.
                  Whether you own a dispensary, weed delivery service, cannabis
                  ecommerce site, or Delta 8 business, we can get you to the top
                  listing in your category.
                  <br />
                  <br />
                  The cannabis industry continues to grow at an exponential
                  rate. With so many businesses flooding the cannabis market,
                  it’s easy for yours to get lost in the sea of search results.
                  Our team of specialists has a thorough understanding of every
                  facet of the cannabis industry, so we know how to get you to
                  the top spot in your category.
                  <br />
                  <br />
                  We help your cannabis business rank high organically, so you
                  can forget about wasting money on paid ads. If your SEO
                  strategy isn’t working, start a conversation with us today.
                  We’ll reinvent your SEO strategy so you pull more customers
                  and get more sales.
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default IndexPage;
