import React from "react";
import { Link } from "gatsby";
import { isMobile } from "react-device-detect";
import VisibilitySensor from "react-visibility-sensor";
import HandImg from "../../assets/images/hand.png";
import HandGif from "../../assets/gifs/hand.gif";
import { loadImage } from "../../utils/generalFun";

const JobSection = ({ showModal }) => {
  const onChangeVisibility = async (isVisible) => {
    if (isVisible && isMobile) {
      let img = await loadImage(HandGif);
      if (img === "success") {
        document.querySelector(
          ".jobsCta"
        ).style.backgroundImage = `url(${HandGif})`;
      }
    } else {
      document.querySelector(
        ".jobsCta"
      ).style.backgroundImage = `url(${HandImg})`;
    }
  };

  const _onMouseEnter = async () => {
    let img = await loadImage(HandGif);
    if (img === "success") {
      document.querySelector(
        ".jobsCta"
      ).style.backgroundImage = `url(${HandGif})`;
    }
  };
  const _onMoutLeave = () => {
    document.querySelector(
      ".jobsCta"
    ).style.backgroundImage = `url(${HandImg})`;
  };

  return (
    <VisibilitySensor onChange={onChangeVisibility} partialVisibility>
      <section
        className="jobsCta publicfigure"
        style={{ backgroundColor: "#000", backgroundImage: `url(${HandImg})` }}
        onMouseLeave={_onMoutLeave}
        onMouseEnter={_onMouseEnter}
        role="none"
      >
        <div className="jobsCta-container">
          <div className="jobsCta-stack">
            <h2
              className="jobsCta-headline"
              style={{ color: "#fff", borderBottom: "1px solid #fff" }}
            >
              Why SEO Cannabis Agency
              <br />
            </h2>
            <p
              className="jobsCta-subheading mobile"
              style={{ color: "#fff", fontSize: 13, lineHeight: "17px" }}
            >
              Your web presence is the #1 credibility indicator for potential
              customers. Is your cannabis brand losing out on sales because of
              its low search results ranking? Stop stressing about your SEO
              strategy. We can help your brand get to the top spot using our
              advanced SEO knowledge...
              <button className="read-more" onClick={showModal}>
                Read More
              </button>
            </p>
            <p
              className="jobsCta-subheading desktop"
              style={{ color: "#fff", fontSize: 13, lineHeight: "17px" }}
            >
              Your web presence is the #1 credibility indicator for potential
              customers. Is your cannabis brand losing out on sales because of
              its low search results ranking? Stop stressing about your SEO
              strategy. We can help your brand get to the top spot using our
              advanced SEO knowledge.
              <br />
              <br />
              We help your cannabis business succeed by taking control of your
              web presence. Our team helps you improve your spot in your
              category’s search results by analyzing your market and the value
              of your keywords. If your cannabis business isn’t at the top of
              the search results where it should be, let our team of specialists
              rethink your SEO strategy.
              <br />
              <br />
              To succeed as a cannabis business, you need to rank high when
              customers search for your brand’s category. Potential customers
              buy from cannabis businesses that rank high because it signals to
              them that they are reputable. Stand out in the crowded cannabis
              market by using an SEO strategy developed by our team of expert
              SEO specialists.
              <br />
              <br />
              Prove to your customers that your cannabis brand is the best.
              Whether you own a dispensary, weed delivery service, cannabis
              ecommerce site, or Delta 8 business, we can get you to the top
              listing in your category.
              <br />
              <br />
              The cannabis industry continues to grow at an exponential rate.
              With so many businesses flooding the cannabis market, it’s easy
              for yours to get lost in the sea of search results. Our team of
              specialists has a thorough understanding of every facet of the
              cannabis industry, so we know how to get you to the top spot in
              your category.
              <br />
              <br />
              We help your cannabis business rank high organically, so you can
              forget about wasting money on paid ads. If your SEO strategy isn’t
              working, start a conversation with us today. We’ll reinvent your
              SEO strategy so you pull more customers and get more sales.
            </p>
            <p className="jobsCta-cta" style={{ color: "#fff" }}>
              <Link
                className="button -cta no-margin"
                to="/contact"
                title="Reach Out"
                style={{ color: "#fff" }}
              >
                Reach Out
                <span className="arrowPacman">
                  <span className="arrowPacman-clip">
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.10081 0L5.88245 1.23617L10.7016 6.12576H0V7.87423H10.7016L5.88245 12.7638L7.10081 14L14 7L7.10081 0Z"
                        fill="#fff"
                      ></path>
                    </svg>
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.10081 0L5.88245 1.23617L10.7016 6.12576H0V7.87423H10.7016L5.88245 12.7638L7.10081 14L14 7L7.10081 0Z"
                        fill="#fff"
                      ></path>
                    </svg>
                  </span>
                </span>
              </Link>
            </p>
          </div>
        </div>
      </section>
    </VisibilitySensor>
  );
};

export default JobSection;
