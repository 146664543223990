export function loadImage(url) {
  return new Promise(function (resolve, reject) {
    let img = new Image();
    // img.onerror = img.onabort = function () {
    //   reject('error');
    // };
    img.onload = function () {
      resolve('success');
    };
    img.src = url;
  });
}
